<template>
  <div class="emb-privacy-policy-wrap">
    <div class="d-flex justify-center text-h4 ma-6 pt-3 font-weight-bold">
      {{ $t("message.privacyPolicy") }}
    </div>
    <div class="d-flex justify-center text-h6">
      {{ $t("message.privacyPolicySubTitle") }}
    </div>
    <div class="section-gap bg-white">
      <div class="container">
        <div class="privacy-page">
          <div
            class="emb-card mb-12 pa-6"
            v-for="(policy, key) in privacyLocale"
            :key="key"
          >
            <div class="mb-4 text-h5">{{ policy.name }}</div>
            <div v-html="policy.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import privacyData from "Assets/data/privacy";
export default {
  data() {
    return {
      privacyLocale: [],
      privacyData,
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.getPrivacyPolicyData();
    },
  },
  mounted() {
    this.getPrivacyPolicyData();
  },
  methods: {
    getPrivacyPolicyData() {
      this.privacyLocale = this.privacyData[this.$i18n.locale];
    },
  },
};
</script>
