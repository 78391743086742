export default {
    "fr": [
        {
            "name": "Introduction",
            "content": "Cette politique de confidentialité décrit la manière dont Lotto Amigo traite les informations et données que vous nous fournissez pour nous permettre de gérer votre relation avec Lotto Amigo.<br>Nous traiterons toutes les informations personnelles qui nous sont fournies ou que nous détenons d'une autre manière vous concernant de la manière indiquée dans la présente politique de confidentialité.  Les informations peuvent être fournies via le site Web de Lotto Amigo (le « Site Web »), des appels téléphoniques ou tout autre moyen.<br>En acceptant cette politique de confidentialité, vous reconnaissez que vous comprenez et acceptez l'utilisation de vos informations personnelles comme indiqué dans cette politique.  Si vous n'êtes pas d'accord avec les termes de cette politique de confidentialité, veuillez ne pas utiliser le site Web ou nous fournir autrement vos informations personnelles."
        },
        {
            "name": "Qui nous sommes",
            "content": "Les références dans la présente politique de confidentialité à « Lotto Amigo », « nous », « notre » ou « notre » se rapportent à BLACKDANES LLC.  Nous contrôlons la manière dont vos Données personnelles sont collectées et les finalités pour lesquelles vos Données personnelles sont utilisées par Lotto Amigo, agissant en tant que « responsable du traitement » aux fins de la législation américaine applicable en matière de protection des données."
        },
        {
            "name": "Nous contacter",
            "content": "Si vous avez des inquiétudes ou si vous souhaitez plus de détails sur la façon dont nous traitons vos données personnelles, vous pouvez nous contacter en utilisant support@lottoamigo.com."
        },
        {
            "name": "Protéger vos données personnelles",
            "content": "Vos données personnelles ne sont pas seulement protégées par la qualité, l'engagement et les normes élevées de Lotto Amigo, elles sont également protégées par la loi.  La loi stipule que nous ne pouvons traiter vos données personnelles que lorsqu'il existe une raison valable de le faire et qu'il doit s'agir de l'une des suivantes :<br>• Pour remplir tout contrat que nous avons avec vous<br>• Nous avons une obligation légale<br>• Lorsque vous avez consenti au traitement<br>• Lorsque cela est dans notre intérêt légitime<br>• Lorsque c'est dans l'intérêt public<br>• Quand c'est dans votre intérêt vital"
        },
        {
            "name": "Intérêts légitimes",
            "content": "Lorsque nous avons une raison commerciale ou commerciale de traiter vos données personnelles, cela est considéré comme un intérêt légitime.  Vos données personnelles sont toujours protégées et nous ne devons pas les traiter d'une manière qui serait injuste pour vous ou vos intérêts.<br>Si nous utilisons des intérêts légitimes comme motif pour traiter vos données personnelles, nous vous indiquerons ce que nous faisons, quels sont nos intérêts légitimes et vous fournirons une méthode pour soulever toute question ou objection que vous pourriez avoir.  Cependant, des motifs impérieux pour le traitement de ces informations peuvent l'emporter sur votre droit d'opposition."
        },
        {
            "name": "Combien de temps conservons-nous vos données personnelles",
            "content": "Chaque fois que vos données sont conservées par Lotto Amigo, nous veillerons à ce qu'elles soient protégées de manière appropriée et utilisées uniquement à des fins acceptables.<br>Nous conserverons vos données tant que vous êtes client de Lotto Amigo.<br>Si vous n'êtes plus client de Lotto Amigo, nous conserverons vos données pendant la durée minimale nécessaire pour se conformer aux finalités énoncées dans la présente politique et aux obligations légales ou réglementaires pertinentes.  Vos Données Personnelles peuvent être conservées plus longtemps si nous ne pouvons pas les supprimer pour des raisons techniques."
        },
        {
            "name": "Pourquoi traitons-nous vos données personnelles",
            "content": "Si vous choisissez de ne pas fournir vos données personnelles, cela peut nous empêcher de respecter les obligations légales, d'exécuter un contrat ou de fournir les services requis pour gérer votre compte.  Ne pas fournir vos données personnelles peut signifier que nous ne sommes pas en mesure de vous fournir des produits ou des services."
        },
        {
            "name": "Informations collectées",
            "content": "Les informations et données vous concernant que nous pouvons collecter, utiliser et traiter comprennent les suivantes :<br>• Les informations que vous nous fournissez en remplissant des formulaires sur le site Web ou toute autre information que vous nous soumettez via le site Web ou par courrier électronique<br>• Enregistrements de la correspondance, que ce soit via le site Web, par courrier électronique, par téléphone ou par d'autres moyens<br>• Vos réponses aux sondages ou aux recherches clients que nous réalisons<br>• Détails des transactions que vous effectuez avec nous, que ce soit via le site Web, par téléphone ou par d'autres moyens<br>• Détails de vos visites sur le site Web, y compris, mais sans s'y limiter, les données de trafic, les données de localisation, les blogs et autres données de communication.<br>Lorsqu'il est raisonnable pour nous de le faire et que cela ne porte pas atteinte à vos droits et libertés, nous collectons également des données personnelles à partir de sources accessibles au public telles que des recherches sur Internet, Companies House et les médias de diffusion."
        },
        {
            "name": "Appels téléphoniques",
            "content": "Les appels téléphoniques vers et depuis notre centre de contact client sont enregistrés à des fins de formation et de sécurité ainsi que pour la résolution de toute question découlant du service que vous recevez."
        },
        {
            "name": "Caractéristiques sociales de nos produits",
            "content": "Si vous choisissez de participer à l'une des fonctionnalités sociales que nous fournissons avec nos produits (telles que les salles de discussion), Lotto Amigo peut stocker, enregistrer ou traiter ces données."
        },
        {
            "name": "Données personnelles que nous partageons avec d'autres.",
            "content": "Nous pouvons partager vos Données Personnelles au sein du groupe BLACKDANES LLC et avec ces autres organisations :<br>• Agences d'application de la loi, régulateurs et autres autorités<br>• Agences de référence de crédit<br>• Agences de prévention de la fraude<br>• Agences de vérification d'identité<br>• Les instances dirigeantes du sport<br>• Les organisations qui vous présentent à nous<br>• Les tiers avec lesquels vous nous demandez (ou nous autorisez) à partager vos données avec<br>• Les tiers nécessaires pour fournir les produits ou services que vous avez demandés<br>Selon les produits que vous choisissez d'utiliser, nous pouvons être amenés à partager vos données personnelles avec les tiers qui fournissent ces services.  Pour ce faire, nous exigeons que des garanties appropriées soient en place.<br>Nous pouvons également divulguer des informations personnelles à d'autres sociétés au sein de sociétés associées ou filiales et à des partenaires commerciaux ou des successeurs en titre de notre entreprise."
        },
        {
            "name": "Transfert de données en dehors de l'Amérique",
            "content": "Nous ne transférerons vos données personnelles en dehors des États-Unis que lorsque :<br>• Vous avez donné votre consentement explicite, ou<br>• Il nous est nécessaire de conclure ou d'exécuter un contrat que vous avez conclu avec nous ;  ou<br>• Pour se conformer à un devoir ou une obligation légale<br>Si nous transférons vos données personnelles en dehors des États-Unis, au sein du groupe ou à nos partenaires commerciaux, nous prendrons des mesures pour nous assurer qu'elles sont protégées selon les mêmes normes qu'elles le seraient en Amérique en nous appuyant sur l'un des éléments suivants :<br>• Le pays qui reçoit vos données personnelles a été identifié par la loi américaine sur la protection de la vie privée de 1974 comme offrant le même niveau de protection que la loi américaine sur la protection de la vie privée de 1974.<br>• Nous utiliserons des contrats qui obligent le destinataire à protéger vos données personnelles selon les mêmes normes que celles prévues par la loi américaine sur la protection de la vie privée de 1974.<br>Dans certains cas, nous pouvons être contraints par la loi de divulguer vos données personnelles à un tiers et pouvons avoir un contrôle limité sur la façon dont elles sont protégées par cette partie."
        },
        {
            "name": "Vos droits sur vos Données Personnelles",
            "content": "Nous vous assisterons si vous choisissez d'exercer l'un de vos droits sur vos données personnelles, notamment :<br>• Retrait de votre consentement précédemment accordé ;  cependant, cela n'invalidera aucun traitement préalablement consenti<br>• Dépôt d'une plainte auprès de toute autorité de protection des données compétente<br>• Accès à vos Données Personnelles que nous détenons ou traitons<br>• Correction de toute donnée personnelle incorrecte ou obsolète<br>• Effacement de toutes les données personnelles que nous traitons<br>• Restreindre le traitement de vos données personnelles dans certaines circonstances<br>• Nous demander de vous fournir, à vous ou à une autre entreprise que vous désignez, certains aspects de vos données personnelles, souvent appelés « droit à la portabilité »<br>• La possibilité de vous opposer à tout traitement de données lorsque nous le faisons pour nos intérêts légitimes<br>• La possibilité de contester une décision prise entièrement par un traitement automatisé, d'exprimer votre point de vue et de demander qu'un humain révise la décision<br>Pour plus d'informations sur ces droits, vous nous contactez à support@lottoamigo.com"
        },
        {
            "name": "Modifications de notre déclaration de confidentialité",
            "content": "Nous pouvons mettre à jour cette politique de temps à autre, veuillez donc la consulter fréquemment.<br>Si des modifications importantes sont apportées à cette politique de confidentialité, nous ferons des efforts raisonnables pour vous informer à l'avance par e-mail, avis sur le site Web ou d'autres canaux de communication convenus.  Nous vous communiquerons les modifications à l'avance, en vous laissant suffisamment de temps pour considérer et comprendre les modifications avant qu'elles ne prennent effet.<br>Nous n'appliquerons pas de modifications importantes à la politique de confidentialité sans votre consentement exprès.  Si vous refusez d'accepter les modifications apportées à la politique de confidentialité, ou si vous n'acceptez pas les modifications dans le délai imparti, nous pourrions ne pas être en mesure de continuer à fournir certains ou tous les produits et services."
        },
        {
            "name": "Politique de cookies",
            "content": "Le site Web de Lotto Amigo (le « Site Web ») utilise des cookies pour offrir un service meilleur et plus personnalisé aux utilisateurs.<br>Cette politique de cookies explique ce que sont les cookies, comment ils sont utilisés sur le site Web et comment gérer leur utilisation."
        }
    ],
    "en": [
        {
            "name": "Introduction",
            "content": "This Privacy Policy describes the way in which Lotto Amigo deal with the information and data you provide to us to enable us to manage your relationship with Lotto Amigo.<br> We will process any personal information provided to us or otherwise held by us relating to you in the manner set out in this Privacy Policy. Information may be provided via the Lotto Amigo website (the \"Website\"), telephone calls or any other means.<br> By accepting this Privacy Policy you agree that you understand and accept the use of your personal information as set out in this policy. If you do not agree with the terms of this Privacy Policy please do not use the Website or otherwise provide us with your personal information."
        },
        {
            "name": "Who we are",
            "content": "References in this Privacy Policy to “Lotto Amigo”, “we”, “us” or “our” relate to BLACKDANES LLC. We control the ways your Personal Data is collected and the purposes for which your Personal Data is used by Lotto Amigo, acting as the “data controller” for the purposes of applicable American data protection legislation."
        },
        {
            "name": "Contacting us",
            "content": "If you have any concerns, or would like more detail about how we process your Personal Data, you can contact us using support@lottoamigo.com."
        },
        {
            "name": "Protecting Your Personal Data",
            "content": "Your Personal Data isn’t just protected by the quality, commitment and high standards of Lotto Amigo, it’s also protected by law. The law states that we can only process your Personal Data when there is a genuine reason to do so and it must be one of the following:<br>• To fulfil any contract that we have with you<br>• We have a legal obligation<br>• Where you have consented to the processing<br>• When it is in our legitimate interest<br>• When it is in the public interest<br>• When it is in your vital interests"
        },
        {
            "name": "Legitimate Interests",
            "content": "When we have a business or commercial reason to process your Personal Data this is referred to as a legitimate interest. Your Personal Data is still protected and we must not process it in a way that would be unfair to you or your interests.<br>If we do use legitimate interests as a reason to process your Personal Data we will tell you that we are doing so, what our legitimate interests are and provide you with a method to raise any questions or objections you may have. However, compelling grounds for processing such information may over-ride your right to object."
        },
        {
            "name": "How long we keep your Personal Data",
            "content": "Whenever your data is kept by Lotto Amigo, we will ensure that it is appropriately protected and only used for acceptable purposes.<br>We will keep your data for the period that you are a customer of Lotto Amigo.<br>If you are no longer a customer of Lotto Amigo, we will keep your data for the minimum length of time required to comply with the purposes set out in this policy and relevant legal or regulatory obligations. Your Personal Data may be kept longer if we cannot delete it for technical reasons."
        },
        {
            "name": "Why we process your Personal Data",
            "content": "If you choose not to provide your Personal Data it may prevent us from meeting legal obligations, fulfilling a contract, or performing services required to run your account. Not providing your Personal Data may mean we are unable to provide you with products or services."
        },
        {
            "name": "Information Collected",
            "content": "The information and data about you which we may collect, use and process includes the following:<br>• Information that you provide to us by filling in forms on the Website or any other information you submit to us via the Website or email<br>• Records of correspondence, whether via the Website, email, telephone or other means<br>• Your responses to surveys or customer research that we carry out<br>• Details of the transactions you carry out with us, whether via the Website, telephone or other means<br>• Details of your visits to the Website including, but not limited to, traffic data, location data, weblogs and other communication data.<br>Where it is reasonable for us to do so and not detrimental to your rights and freedoms, we also collect Personal Data from publicly available sources such as internet searches, Companies House, and broadcast media."
        },
        {
            "name": "Telephone Calls",
            "content": "Telephone calls to and from our Customer Contact Centre are recorded for training and security purposes along with the resolution of any queries arising from the service you receive."
        },
        {
            "name": "Social Features of Our Products",
            "content": "If you choose to participate in any of the social features that we provide with our products (such as chat rooms) Lotto Amigo may store, record or otherwise process this data."
        },
        {
            "name": "Personal Data we share with others",
            "content": "We may share your Personal Data within the BLACKDANES LLC group and with these other organisations:<br>• Law enforcement agencies, regulators and other authorities<br>• Credit reference agencies<br>• Fraud prevention agencies<br>• Identity verification agencies<br>• Sports governing bodies<br>• Organisations that introduce you to us<br>• Third parties you ask us (or permit us) to share your data with<br>• Third parties necessary to provide products or services which you have requested<br>Depending on the products you choose to use we may need to share your Personal Data with the third parties that provide those services. In order to do so, we require that appropriate safeguards are in place.<br>We may also disclose personal information to other companies within associated or subsidiary companies and to business partners, or successors in title to our business."
        },
        {
            "name": "Data Transfer Outside America",
            "content": "We will only transfer your Personal Data outside of America where:<br>• You have given your explicit consent, or<br>• It is necessary for us to set up or fulfil a contract you have entered into with us; or<br>• To comply with a legal duty or obligation<br>If we do transfer your Personal Data outside of America, within the group or to our business partners, we will take measures to ensure it is protected to the same standards as it would be within America by relying on one of the following:<br>• The country that is receiving your Personal Data has been found by the US Privacy Act of 1974 to offer the same level of protection as the US Privacy Act of 1974.<br>• We will use contracts that require the recipient to protect your Personal Data to the same standards as it would be within the US Privacy Act of 1974<br>In some instances we may be compelled by law to disclose your Personal Data to a third party and may have limited control over how it is protected by that party.."
        },
        {
            "name": "Your rights over your Personal Data",
            "content": "We will assist you if you choose to exercise any of your rights over your Personal Data, including:<br>• Withdrawing your previously granted consent; however, this will not invalidate any previously consented processing<br>• Lodging a complaint with any relevant Data Protection Authority<br>• Access to your Personal Data that we hold or process<br>• Correction of any Personal Data that is incorrect or out of date<br>• Erasure of any Personal Data that we process<br>• Restrict processing of your Personal Data in certain circumstances<br>• Asking us to provide you or another company you nominate with certain aspects of your Personal Data, often referred to as ‘the right to portability’<br>• The ability to object to any processing data where we are doing it for our legitimate interests<br>• The ability to contest a decision made entirely by automated processing, to express your point of view and to request that a human review the decision<br>For more information on these rights you contact us at support@lottoamigo.com"
        },
        {
            "name": "Changes to our Privacy Statement",
            "content": "We may update this policy from time to time, so please review it frequently.<br>If any material changes are made to this Privacy Policy we will use reasonable endeavours to inform you in advance by email, notice on the Website or other agreed communications channels. We will communicate the changes to you in advance, giving an appropriate amount of time for you to consider and understand the changes before they become effective.<br>We will not enforce material changes to the Privacy Policy without your express consent. If you decline to accept the changes to the Privacy Policy, or otherwise do not accept the changes within the time period, we may not be able to continue to provide some or all products and services."
        },
        {
            "name": "Cookies policy",
            "content": "The Lotto Amigo website (the \"Website\") uses cookies to deliver a better and more personalised service to users.<br>This Cookies Policy explains what cookies are, how they are used on the Website and how to manage their use.<br>What is a Cookie?<br>Cookies are small text files containing small amounts of information which are sent to and stored on your computer, smartphone or other device when you access a website. Cookies are then sent back to the originating website on each subsequent visit or to another website that recognises that cookie. Cookies are useful because they allow a website to recognise a user's device."
        }
    ]
}